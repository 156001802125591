<template>
  <div>
    <v-card class="mb-6 ml-6 mt-6 mr-2">
      <v-card-title> Liste des Utilisateurs </v-card-title>
      <v-row>   <v-col cols="6" sm="6" md="6"> <v-text-field
        style="padding:15px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        @clear="resetSearch"
       
      ></v-text-field> </v-col>
      <v-col cols="6" sm="6" md="6">
        <v-btn
             @click="reSearchByKeyWord()"
              color="primary"
              class="me-3 mt-3"
              style="background-color: #A8FE39; color:#ffffff"
            >
            Recherche
            </v-btn>
            <v-btn
             @click="resetSearch()"
              class="me-3 mt-3"
              style="background-color: #f73131; color:#ffffff"
            >
            Clear
            </v-btn>
      </v-col>
    </v-row>
     
    
      <v-data-table
      :loading="isLoadingTables"
      loading-text="Loading..."
      hide-default-footer
        :headers="headers"
        :items="users"
        class="elevation-1"
        item-key="uid"
        
      >
        <template v-slot:item.creationDate="{ item }">
          {{ formatDate(item.creationDate) }}
        </template>
        <template v-slot:item.Delete="{ item }">
          <v-icon @click="DeleteAction(item)">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>
        <template v-slot:item.administrateur="{ item }">
          <v-icon @click="suppressionAction(item)">
            {{ icons.mdiAccountKey }}
          </v-icon>
        </template>
      </v-data-table>
      <v-row>
        <v-col cols="2" sm="2" md="2"></v-col>
        <v-col cols="8" sm="8" md="8">
      <v-pagination
        v-if="showPagination"
        v-model="page"
        :length="pageCount"
        :total-visible="7"
      ></v-pagination> </v-col> 
      <v-col style="margin-top:15px" cols="2" sm="2" md="2">Total d'utilisateurs: {{totale}}</v-col>
    </v-row>
    </v-card>
    <v-row justify="center">
      <v-dialog v-model="dialogDeleteUser" max-width="600">
        <v-card color="text-center">
          <v-card-text class="d-flex flex-column justify-center align-center">
            <v-avatar color="primary" class="mt-10" icon size="50">
              <v-icon size="2rem" color="white">
                {{ icons.mdiAlertCircleOutline }}
              </v-icon>
            </v-avatar>
          </v-card-text>
          <v-card-text
            ><h2 style="color: black;">
              Etes-vous sûr de vouloir ajouter l 'utilisateur </br> <a  >{{this.mailUserSupprimmer}}</a></br>  en tant
              qu'administrateur ?
            </h2>
          </v-card-text>
          <v-card-text>
            <v-btn
              @click="disableUserLogin()"
              color="primary"
              class="mt-4"
              style="background-color: #A8FE39; color: aliceblue;"
            >
              Confirmer
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogDelete" max-width="600">
        <v-card color="text-center">
          <v-card-text class="d-flex flex-column justify-center align-center">
            <v-avatar color="error" class="mt-10" icon size="50">
              <v-icon size="2rem" color="white">
                {{ icons.mdiAlertCircleOutline }}
              </v-icon>
            </v-avatar>
          </v-card-text>
          <v-card-text
            ><h2 style="color: black;">
              Etes-vous sûr de vouloir Supprimer l 'utilisateur </br> <a color="error" >{{this.mailUserDelete}}</a>
           
            </h2>
          </v-card-text>
          <v-card-text>
            <v-btn
              @click="DeleteUserLogin()"
              color="error"
              class="mt-4"
              style="background-color: #ff0000; color: aliceblue;"
            >
              Confirmer
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiPencil,
  mdiDatabasePlusOutline,
  mdiAlertCircleOutline,
  mdiAccountKey,
} from "@mdi/js";
import { getAllUserstByRole,SearchByKeyWord } from "@/views/qorani/services/services";
import Constant from "@/utils/constants";
import "firebase/auth";
export default {
  data() {
    return {
      totale: null,
      list:{},
      pageCount: 0,
      page: 1,
      itemsPerPage: 10,
      showPagination:true,
      search: "",
      icons: {
        mdiAlertCircleOutline,
        mdiPlus,
        mdiDeleteOutline,
        mdiPencil,
        mdiDatabasePlusOutline,
        mdiAccountKey,
      },
      users: [],
      mailUserDelete: null,
      idUserDelete:null,
      dialogDelete:false,
      isLoadingTables: false,
      idUserSupprimmer: null,
      mailUserSupprimmer: null,
      dialogDeleteUser: false,
      headers: [
        { text: "Email", value: "email" },
        { text: "Nom", value: "lastName" },
        { text: "Prénom", value: "firstName" },
        {
          text: "Ajouter en tant qu'administrateur",
          value: "administrateur",
          align: "center",
        },
        {
          text: "Supprimer utilisateur",
          value: "Delete",
          align: "center",
        },
      ],
    };
  },
  async created() {
    this.isLoadingTables =true
    this.list = await getAllUserstByRole("USER",0);
    this.users =  this.list.userList;
    this.totale = this.list.totalNbrElement;
    this.pageCount =  this.list.nbrTotalPage;
     this.isLoadingTables =false
  },
  watch: {
    async page(newValue) {
      this.isLoadingTables = true;

      const responseVerset = await getAllUserstByRole("USER",newValue - 1);
      this.users = responseVerset.userList;

      this.isLoadingTables = false;
    },
  },
  methods: {
    async reSearchByKeyWord(){
      this.users =[]
      this.list=[]
    this.isLoadingTables =true
    this.list = await SearchByKeyWord(this.search);
    this.users =  this.list;
    this.pageCount = 0;
    this.isLoadingTables =false
    },
    async resetSearch(){
      this.search ="",
    this.isLoadingTables =true
    this.list = await getAllUserstByRole("USER",0);
    this.users =  this.list.userList;
    this.pageCount =  this.list.nbrTotalPage;
    this.isLoadingTables =false
    },
    async disableUserLogin() {
      const response = await fetch(
        Constant.QORANI + `user/editUserRoleByUserId/${this.idUserSupprimmer}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      try {
        this.dialogDeleteUser = false;
        window.location.reload();
      } catch (error) {
        alert(
          "Une erreur est survenue lors de la suppression du juz, veuillez réessayez plus tard, Merci."
        );
        this.dialogDeleteUser = false;
      }
    },
    async DeleteUserLogin() {
      const response = await fetch(
        Constant.QORANI + `user/deleteUser/${this.idUserDelete}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      try {
        this.dialogDelete = false;
        window.location.reload();
      } catch (error) {
        alert(
          "Une erreur est survenue lors de la suppression du juz, veuillez réessayez plus tard, Merci."
        );
        this.dialogDelete = false;
      }
    },
    formatDate(creationDate) {
      return moment(new Date(creationDate.seconds * 1000)).format(
        "YYYY-MM-DD HH:mm"
      );
    },
    DeleteAction(item){
      this.mailUserDelete = item.email;
      this.idUserDelete = item.userId;
      this.dialogDelete = true;
    },
    suppressionAction(item) {
      this.mailUserSupprimmer = item.email;
      this.idUserSupprimmer = item.userId;
      this.dialogDeleteUser = true;
    },
   
  },
};
</script>
